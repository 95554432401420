import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowBackOutlined,
  CreditCard,
  Email,
  Factory,
  Logout,
  Loyalty,
  Mail,
  MenuOutlined,
  MilitaryTech,
  Notifications,
  People,
  ReceiptLong,
  Settings,
  SpaceDashboard,
  Store,
  VerifiedUser,
} from "@mui/icons-material";
import { Outlet, useNavigate, useRouterState } from "@tanstack/react-router";
import { useMutation } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { LinkWrapper, Logo } from "@repo/ui";
import { useCmsRole, useCompanyAuth, useMe } from "@repo/api-config";
import {
  AppRouteValues,
  cmsRoutes,
  useNotificationsContext,
} from "@repo/utils";
import { I18nContext, useTranslation } from "@repo/i18n-config";

interface MenuItem {
  title: string;
  link: string;
  icon: JSX.Element;
}

const drawerWidth: string = "280px";

const DashboardTemplate = () => {
  const { logout } = useCompanyAuth();
  const navigate = useNavigate();
  const { userType, userRole } = useMe(useCmsRole());
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "cms");

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        title: "Dashboard",
        link: cmsRoutes.home.base,
        icon: <SpaceDashboard />,
      },
      ...(userType === "Root"
        ? [
            {
              title: t("dashboard.menuItems.companies"),
              link: cmsRoutes.companies.base,
              icon: <Factory />,
            },
            {
              title: t("dashboard.menuItems.subscriptions"),
              link: cmsRoutes.subscriptions.base,
              icon: <CreditCard />,
            },
            {
              title: t("dashboard.menuItems.plans"),
              link: cmsRoutes.plans.base,
              icon: <Loyalty />,
            },
          ]
        : []),
      ...(userRole === "Owner"
        ? [
            {
              title: t("dashboard.menuItems.rewards"),
              link: cmsRoutes.rewards.base,
              icon: <MilitaryTech />,
            },
            {
              title: t("dashboard.menuItems.employees"),
              link: cmsRoutes.employees.base,
              icon: <VerifiedUser />,
            },
            {
              title: t("dashboard.menuItems.customers"),
              link: cmsRoutes.customers.base,
              icon: <People />,
            },
            {
              title: t("dashboard.menuItems.locations"),
              link: cmsRoutes.locations.base,
              icon: <Store />,
            },
            {
              title: t("dashboard.menuItems.notifications"),
              link: cmsRoutes.notifications.base,
              icon: <Notifications />,
            },
            {
              title: t("dashboard.menuItems.mail"),
              link: cmsRoutes.mailNotifications.base,
              icon: <Mail />,
            },
            {
              title: t("dashboard.menuItems.invoices"),
              link: cmsRoutes.invoices.base,
              icon: <ReceiptLong />,
            },
            {
              title: t("dashboard.menuItems.settings"),
              link: cmsRoutes.settings.base,
              icon: <Settings />,
            },
            {
              title: t("dashboard.menuItems.contacts"),
              link: "mailto:support@loymee.com",
              icon: <Email />,
            },
          ]
        : []),
      ...(userRole === "PendingOwner"
        ? [
            {
              title: t("dashboard.menuItems.rewards"),
              link: cmsRoutes.rewards.base,
              icon: <MilitaryTech />,
            },
            {
              title: t("dashboard.menuItems.employees"),
              link: cmsRoutes.employees.base,
              icon: <VerifiedUser />,
            },
            {
              title: t("dashboard.menuItems.locations"),
              link: cmsRoutes.locations.base,
              icon: <Store />,
            },
            {
              title: t("dashboard.menuItems.settings"),
              link: cmsRoutes.settings.base,
              icon: <Settings />,
            },
            {
              title: t("dashboard.menuItems.contacts"),
              link: "mailto:support@loymee.com",
              icon: <Email />,
            },
          ]
        : []),
    ],
    [userRole]
  );

  const { showNotification } = useNotificationsContext();
  const { t: commonT } = useTranslation(lang, "common");
  const { mutate: logoutMutation } = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      showNotification(commonT("loggedOut"));
      navigate({
        to: userType === "Root" ? cmsRoutes.login.admin : cmsRoutes.login.base,
      });
    },
  });

  const { location } = useRouterState();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ pl: isMobile ? 0 : drawerWidth }}>
      <Drawer
        PaperProps={{
          elevation: 7,
          sx: { width: 280, border: "none" },
        }}
        open={mobileMenuOpen}
        variant={isMobile ? "temporary" : "permanent"}
        onClose={() => setMobileMenuOpen(false)}
      >
        <Box sx={{ p: 4 }} display="flex" justifyContent="center">
          <Logo theme="dark" />
        </Box>
        <List
          sx={{
            p: 2,
            pb: 3,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {menuItems.map((item) =>
            item.link.startsWith("mailto:") ? (
              <a href={item.link} key={`menuItem_${item.title}`}>
                <ListItem
                  onClick={() => setMobileMenuOpen(false)}
                  selected={location.pathname === item.link}
                >
                  {item.icon}
                  {item.title}
                </ListItem>
              </a>
            ) : (
              <LinkWrapper
                to={item.link as AppRouteValues}
                key={`menuItem_${item.title}`}
              >
                <ListItem
                  onClick={() => setMobileMenuOpen(false)}
                  selected={location.pathname === item.link}
                >
                  {item.icon}
                  {item.title}
                </ListItem>
              </LinkWrapper>
            )
          )}
          <ListItem
            onClick={() => logoutMutation()}
            sx={{
              mt: "auto",
              cursor: "pointer",
            }}
          >
            <Logout />
            {t("dashboard.logout")}
          </ListItem>
        </List>
      </Drawer>
      <Box sx={{ pt: isMobile ? 2 : 4, pb: 2, mx: isMobile ? 2 : 4 }}>
        {isMobile && (
          <IconButton
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            sx={{
              ml: -1.5,
            }}
          >
            <MenuOutlined />
          </IconButton>
        )}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            mb: 1,
            mt: {
              xs: 2,
              md: 0,
            },
          }}
        >
          {(location.pathname.endsWith("/new") ||
            location.pathname.includes("/edit")) && (
            <IconButton
              onClick={() =>
                navigate({
                  to: location.pathname
                    .replace("/new", "")
                    .replace(new RegExp("/edit.*"), ""),
                })
              }
              sx={{
                ml: -1.5,
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
          )}
          <Typography variant="h5" fontWeight={700}>
            {menuItems.find((item) => item.link === location.pathname)?.title}
          </Typography>
        </Box>
        {/* Redundant component - to be used in future */}
        {/* <AppBar
          position="relative"
          sx={{
            p: 2,
            borderRadius: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <LinkWrapper to={cmsRoutes.profile.base}>
            <IconButton>
              <PersonOutline />
            </IconButton>
          </LinkWrapper>
        </AppBar> */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardTemplate;
